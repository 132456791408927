@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'Noto Sans', 'Open Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
		background-color: rgba(244, 247, 254, 1);
	}

	html,
	body,
	#root {
		width: 100%;
		height: auto;
		min-height: 100%;
	}
}

.bg-darkPrimary {
	background: linear-gradient(-140deg, #12141d, #18354c);
}

.bg-sidebarPrimary {
	background-color: #12141d !important;
	background: linear-gradient(-140deg, #12141d, #18354c);
	background-blend-mode: normal;
	background-color: #1e2546;
}

.bg-lightPrimary {
	background: rgba(244, 247, 254, var(--tw-bg-opacity));
}

.card {
	@apply bg-white shadow-md rounded-cs border border-gray-200;
}

.card-pad {
	@apply p-6;
}

.rounded-cs {
	border-radius: 3px;
}

/* Buttons */
.btn {
	min-height: 38px;
	padding: 4px 12px;
	@apply text-sm transition duration-200 rounded-md;
}

.btn-primary {
	@apply bg-green-600 text-white;
}

.btn-default {
	@apply bg-gray-200 text-primary;
}

.btn-action {
	@apply bg-white border border-gray-200 border border-gray-300 text-primary;
}


.autocomplete-override>div {
	border-color: rgb(203 213 224 / 300) !important;
	border-radius: 5px !important
}

.text-primary {
	color: #12141d;
}

input:placeholder {
	font-size: 400 !important;
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

input.chakra-input+.chakra-popover__popper {
	z-index: 9999;
}

.custom-select::-ms-expand {
	display: none;
}

.chakra-select__wrapper .chakra-select__icon-wrapper {
	display: none;
}

input[readonly],
input[disabled],
.daterange-picker[disabled],
select[disabled],
textarea[disabled] {
	cursor: not-allowed;
	background-color: rgb(237 242 247 / 1) !important;
	border-color: rgb(237 242 247 / 1) !important;
	color: #1B254B !important;
	opacity: 0.6;
}

button[disabled] {
	cursor: not-allowed;
	opacity: 0.6 !important;
}

select:focus {
	outline: none !important;
}


.status {
	font-size: 11px;
	@apply rounded-3xl min-w-24 text-center transition duration-200 px-4 py-0 text-white font-semibold tracking-tight font-bold border border-gray-400;
}

.status-completed,
.status-successful,
.status-settled,
.status-active,
.status-enabled,
.status-confirmed {
	color: #4fb06d;
	border-color: #4fb06d;
}

.status-pending,
.status-setup-required {
	color: #5c62d6;
	border-color: #5c62d6;
}

.status-queued,
.status-unassessed,
.status-open,
.status-awaiting {
	color: #253342;
	border-color: #253342;
}

.status-in_progress,
.status-successful_pending_kyc,
.status-settled_with_error,
.status-first-sign-in-required,
.status-forced-reset,
.status-unconfirmed {
	color: #f07857;
	border-color: #f07857;
}

.status-closed {
	color: #00dfba;
	border-color: #00dfba;
}

.status-sent_mail {
	color: #be398d;
	border-color: #be398d;
}

.status-cancelled,
.status-failed,
.status-inactive,
.status-disabled {
	color: #bf2c34;
	border-color: #bf2c34;
}

.rounded-tl-custom {
	border-top-left-radius: 20px;
}

.rounded-tr-custom {
	border-top-right-radius: 20px;
}

.rounded-br-custom {
	border-bottom-left-radius: 20px;
}

.rounded-bl-custom {
	border-bottom-right-radius: 20px;
}

.mui-table-custom {
	font-feature-settings: 'kern' !important;
	-webkit-font-smoothing: antialiased;
	letter-spacing: -0.5px;
	border: none !important;
}

.mui-row-no-border .MuiDataGrid-cell {
	border: none !important;
}

.mui-row-no-border:hover {
	background-color: none !important;
}

::placeholder {
	font-weight: 700;
}

input[disabled]::placeholder {
	opacity: 0.6;
	background-color: rgb(237 242 247 / 1) !important;
	border-color: rgb(237 242 247 / 1) !important;
}

.swal2-modal {
	border-radius: 20px !important;
}

.custom-swal2-popup-action {
	text-transform: capitalize !important;
}

.swal2-deny,
.swal2-cancel,
.custom-swal2-popup-action-cancel {
	color: inherit !important;
	background-color: #DADEEC !important;
	font-weight: normal !important;
}

.swal2-confirm,
.custom-swal2-popup-action-confirm {
	color: #fff !important;
	background-color: #36bb83 !important;
	font-weight: normal !important;
}

.custom-swal2-popup-action:focus,
.custom-swal2-popup-action:visited,
.swal2-confirm:focus,
.swal2-confirm:visited,
.swal2-deny:focus,
.swal2-deny:visited,
.swal2-cancel:focus,
.swal2-cancel:visited {
	box-shadow: none !important;
}

.custom-swal2-popup-title {
	font-size: 1.2rem !important;
}

.custom-swal2-popup-session-expire-title {
	padding-top: 30px;
	font-size: 1.2rem !important;
}

.create-payout-receiver__control,
.create-payout-receiver__menu,
.search-payout-receiver__control,
.search-payout-receiver__menu,
.search-receiver__control,
.search-receiver__menu {
	background-color: #fff !important;
	border: 1px solid rgb(233 236 239 / 1) !important;
	border-radius: 0.75rem !important;
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
	min-height: 40px !important;
	cursor: text !important;
}

.create-payout-receiver__indicators,
.search-payout-receiver__indicators,
.search-receiver__indicators {
	cursor: pointer !important;
}

.create-payout-receiver__placeholder,
.search-payout-receiver__placeholder,
.search-receiver__placeholder {
	font-size: 14px;
	font-weight: 700 !important;
	color: rgb(27 37 75 / 1) !important;
}

.create-payout-receiver__control--is-disabled .create-payout-receiver__value-container .create-payout-receiver__placeholder,
.search-payout-receiver__control--is-disabled .search-payout-receiver__value-container .search-payout-receiver__placeholder,
.search-receiver__control--is-disabled .search-receiver__value-container .search-receiver__placeholder {
	font-size: 14px;
	color: rgb(27 37 75 / 0.6) !important;
}

.create-payout-receiver__control.create-payout-receiver__control--is-disabled,
.search-payout-receiver__control.search-payout-receiver__control--is-disabled,
.search-receiver__control.search-receiver__control--is-disabled {
	background-color: rgb(244, 247, 250) !important;
	border-color: rgb(244, 247, 250) !important;
	color: #1B254B !important;
}

.create-payout-receiver__menu,
.search-payout-receiver__menu,
.search-receiver__menu {
	margin-top: 3px !important;
}

.create-payout-receiver__menu-list,
.search-payout-receiver__menu-list,
.search-receiver__menu-list {
	padding: 5px !important;
}

.create-payout-receiver__option,
.search-payout-receiver__option,
.search-receiver__option {
	background-color: #fff !important;
	border-radius: 10px !important;
	font-weight: 500 !important;
	color: rgb(27 37 75 / 1) !important;
	font-size: 0.875rem !important;
	line-height: 1.25rem !important;
}

.create-payout-receiver__single-value,
.create-payout-receiver__input-container,
.search-payout-receiver__single-value,
.search-payout-receiver__input-container,
.search-receiver__single-value,
.search-receiver__input-container {
	font-size: 0.875rem !important;
	line-height: 1.25rem !important;
}

.create-payout-receiver__option:hover,
.search-payout-receiver__option:hover,
.search-receiver__option:hover {
	background-color: #eef0f6 !important;
	border-radius: 10px !important;
}

.create-payout-receiver__indicator-separator,
.create-payout-receiver__dropdown-indicator,
.search-receiver__indicator-separator,
.search-receiver__dropdown-indicator {
	display: none !important;
}

.create-payout-receiver.__placeholder {
	font-size: 0.875rem !important;
}

.create-payout-receiver.__indicators {
	display: none !important;
}

.search-payout-receiver__indicator-separator {
	display: none !important;
}

.search-payout-receiver__dropdown-indicator,
.search-receiver__dropdown-indicator {
	color: rgb(27 37 75 / 1) !important;
}

.search-payout-receiver__dropdown-indicator svg,
.search-receiver__dropdown-indicator svg {
	height: 15px;
	width: 15px;
}

.search-payout-receiver__control.search-payout-receiver__control--is-disabled .search-payout-receiver__indicators .search-payout-receiver__dropdown-indicator svg,
.search-receiver__control.search-receiver__control--is-disabled .search-receiver__indicators .search-receiver__dropdown-indicator svg {
	color: rgb(27 37 75 / 0.6) !important;
}

.create-payout-receiver__clear-indicator svg,
.search-payout-receiver__clear-indicator svg,
.search-receiver__clear-indicator svg {
	width: 18px !important;
	height: 18px !important;
	cursor: pointer;
}

.create-payout-receiver__single-value,
.search-payout-receiver__single-value,
.search-receiver__single-value {
	color: rgb(27 37 75 / 1) !important;
}


input[type="checkbox"] {
	accent-color: #3ccf91;
}

.slide-down-enter {
	opacity: 0;
	transform: translateY(-20px);
}

.slide-down-enter-active {
	opacity: 1;
	transform: translateY(0);
	transition: opacity 0.4s, transform 0.4s;
}

.slide-down-exit {
	opacity: 1;
	transform: translateY(0);
}

.slide-down-exit-active {
	opacity: 0;
	transform: translateY(-20px);
	transition: opacity 0.4s, transform 0.4s;
}

/* Hide the increment and decrement buttons */
.number-input::-webkit-inner-spin-button,
.number-input::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.MuiCircularProgress-svg {
	color: #3CCF91;
}

.MuiDataGrid-row {
	background-color: inherit !important;
}

.MuiDataGrid-row:hover {
	background-color: inherit !important;
}

.MuiDataGrid-columnHeader {
	background-color: #18354c !important;
	color: #FFF;
}

.MuiDataGrid-columnHeaderTitle {
	font-weight: 600 !important;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
	outline: none !important;
	@apply text-sm;
}

.MuiDataGrid-row:hover .MuiDataGrid-cell {
	background-color: #f3f7f6;
}

.MuiDataGrid-cell {
	font-size: 15px;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.spinner {
	animation: spin 0.8s linear infinite;
}

.react-datepicker {
	font-family: inherit !important;
	font-size: inherit !important;
	font-weight: inherit !important;
	border: 1px solid #C9D0E3 !important;
}

.react-datepicker__header {
	border-bottom: 1px solid #C9D0E3 !important;
}

.react-datepicker__navigation.react-datepicker__navigation--previous,
.react-datepicker__navigation.react-datepicker__navigation--next {
	top: 5px;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
	fill: white !important;
}

.react-datepicker-popper .react-datepicker__triangle {
	stroke: #C9D0E3 !important;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
	background-color: white !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background-color: #36BB83 !important;
	color: white !important;
}

.react-datepicker-wrapper {
	width: 100%;
	/* Makes the wrapper full-width */
}

.react-datepicker__input-container input {
	width: 100%;
	/* Ensures the input itself is full-width */
	box-sizing: border-box;
}

.react-datepicker__input-container input[readonly] {
	background-color: white !important;
	border: 1px solid rgb(203 213 224 / 1) !important;
	padding: 9px !important;
	opacity: 1 !important;
}

.child-tab[aria-selected="false"] {
	padding-bottom: 1rem;
	/* Equivalent to pb-4 */
}

.child-tab[aria-selected="true"] {
	border-bottom-width: 4px;
	border-color: #36BB83;
	/* Equivalent to border-green-500 */
}

.custom-shadow {
	-webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

input[readonly].custom-readonly {
    background-color: white !important;
    color: #000000 !important;
	border: 1px solid #D1D5DB !important;
}

.custom-sidebar-available-balance-warpper {
	border-color: rgb(163 174 208) !important;
	background-color: #394b61 !important;
	padding: 0px 7px;
}

.custom-sidebar-available-balance-scrollbar::-webkit-scrollbar {
	background-color: transparent;
	width: 4px;
}

.custom-sidebar-available-balance-scrollbar::-webkit-scrollbar-thumb {
	background-color: #576a81;
	max-height: 5px !important;
}